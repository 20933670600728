import logo from './Temmie.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          To download the installer go to temmie.technology/download.zip
          If you dont want to go through the shitty installer process that i made and just want the dll go
          to temmie.technology/Temware.dll
        </p>
        
      </header>
    </div>
  );
}

export default App;
